import React, {useState, useEffect} from 'react';
import {t} from 'ttag';
import update from 'immutability-helper';

import {Checkbox} from 'components/form/form';
import Wrapper from 'components/wrapper/wrapper';
import PhoneCodeEditor from '../components/phone-code-editor/phone-code-editor';
import * as Title from 'components/title/title';

import {useAppContext} from '../../../../../app-context.js';
import {useRequest} from '../../../../../common/hooks';
import {
  deleteDialingCodeReq,
  getDialingCodeReq,
  updateDialingCodeReq,
  getChatInCall,
  setChatInCall
} from '../../../../../common/api/bitrix24.js';

const Bitrix24 = () => {
  const {user, setNotificationList} = useAppContext();

  const [dialingCode, setDialingCode] = useState();
  const [isChatInCall, setIsChatInCall] = useState();

  const {crmRequest} = useRequest();

  const getValue = async () => {
    const res = await crmRequest({
      crm: user.crm,
      ...getChatInCall(user.domain)
    });

    setIsChatInCall(typeof res == 'boolean' ? res : !!res?.data);
  };

  useEffect(() => {
    getValue();
  }, []);

  const getDialingCode = async () => {
    const res = await crmRequest({
      crm: user.crm,
      ...getDialingCodeReq(user.domain)
    });

    const pc =
      typeof res == 'string'
        ? res
        : typeof res?.data == 'string'
          ? res.data
          : '';

    setDialingCode(pc);
  };

  useEffect(() => {
    getDialingCode();
  }, []);

  const handleSubmit = async values => {
    const code = values.phoneCode.trim();

    await Promise.all([
      crmRequest({
        crm: user.crm,
        ...(code
          ? updateDialingCodeReq({domain: user.domain, code})
          : deleteDialingCodeReq(user.domain))
      }),
      crmRequest({
        crm: user.crm,
        ...setChatInCall(user.domain, !!isChatInCall)
      })
    ]);

    setNotificationList(prevValue =>
      update(prevValue, {
        $push: [
          {
            title: t`The settings have been successfully saved.`
          }
        ]
      })
    );

    return true;
  };

  return (
    <Wrapper gap={16} isColumn>
      <div>
        <Title.H3 style={{marginBottom: 8}}>{t`Call card`}</Title.H3>

        <Checkbox
          isChecked={!!isChatInCall}
          isDisabled={typeof isChatInCall != 'boolean'}
          label={t`Chat in a call card`}
          onChange={setIsChatInCall}
        />
      </div>

      <PhoneCodeEditor phoneCode={dialingCode} onSubmit={handleSubmit} />
    </Wrapper>
  );
};

export default Bitrix24;
