import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import * as Title from '../../../../../title/title.jsx';
import {Input} from '../../../../../form/form.jsx';

const Email = () => {
  const [field, , helpers] = useField('email');

  return (
    <div>
      <Title.H3 style={{marginBottom: 8}}>{t`Email`}</Title.H3>

      <Input
        {...field}
        type="email"
        placeholder={t`Email`}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Email;
