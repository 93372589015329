import React, {useState, useEffect} from 'react';
import {t} from 'ttag';
import update from 'immutability-helper';

import * as Title from 'components/title/title';
import Wrapper from 'components/wrapper/wrapper';
import {Checkbox} from 'components/form/form';
import PhoneCodeEditor, {
  PhoneCodeEditorValues
} from '../components/phone-code-editor/phone-code-editor';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {
  fetchShortCode,
  fetchStopFiles,
  putShortCode,
  putStopFiles
} from 'common/api/monday';

interface Result {
  short_code: string;
}

interface Res {
  result?: Result;
  success: boolean;
}

interface StopFiles {
  result?: {stop_files: boolean};
}

const Monday = () => {
  const {setNotificationList, user} = useAppContext();

  const [isStopFiles, setIsStopFiles] = useState(false);
  const [shortCode, setShortCode] = useState<string>();

  const {crmRequest} = useRequest();

  const getShortCode = async () => {
    const res: Res | undefined = await crmRequest({
      crm: user.crm,
      ...fetchShortCode(user.domain)
    } as any); // eslint-disable-line

    setShortCode(res?.result?.short_code || '');
  };

  const getStopFiles = async () => {
    const res: StopFiles = await crmRequest({
      crm: user.crm,
      ...fetchStopFiles(user.domain)
    } as any); // eslint-disable-line

    setIsStopFiles(!!res.result?.stop_files);
  };

  useEffect(() => {
    getShortCode();
    getStopFiles();
  }, []);

  const handleSubmit = async (values: PhoneCodeEditorValues) => {
    const phoneCode = values.phoneCode.trim();

    const res: Res | undefined = await crmRequest({
      crm: user.crm,
      ...putShortCode(user.domain, phoneCode)
    } as any); // eslint-disable-line

    const stop: Res | undefined = await crmRequest({
      crm: user.crm,
      ...putStopFiles(user.domain, isStopFiles)
    } as any); // eslint-disable-line

    if (res?.success && stop?.success)
      // eslint-disable-next-line
      setNotificationList((prevValue: any) =>
        update(prevValue, {
          $push: [
            {
              title: t`Settings updated`
            }
          ]
        })
      );

    return true;
  };

  return (
    <Wrapper gap={16} isColumn>
      <div>
        <Title.H3 style={{marginBottom: 8}}>{t`File upload`}</Title.H3>

        <Checkbox
          isChecked={!isStopFiles}
          label={t`Upload files to monday.com`}
          onChange={value => setIsStopFiles(!value)}
        />
      </div>

      <PhoneCodeEditor onSubmit={handleSubmit} phoneCode={shortCode} />
    </Wrapper>
  );
};

export default Monday;
