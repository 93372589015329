import React from 'react';
import {t} from 'ttag';
import Wrapper from '../../components/wrapper/wrapper.jsx';
import Text from '../../components/text/text.jsx';
import Link from '../../components/link/link.jsx';
import {useAppContext} from '../../app-context.js';

const Footer = () => {
  const {lang, whatsAppSupport, licenseAgreement} = useAppContext();

  const privacyPolicy = `https://whatcrm.net/${lang}/privacy-policy`;
  const year = new Date().getFullYear();

  return (
    <footer className="footer">
      <Wrapper justifyContent="space-between" alignItems="center" gap={16}>
        <Wrapper alignItems="center" gap={16}>
          <Text color="dark" isSmall>
            {`2020 — ${year}`}
          </Text>

          <Text isSmall>
            <Link
              color="dark"
              href={`https://whatcrm.net/${lang}`}
              target="_blank"
              isSmall
            >
              {'whatcrm.net'}
            </Link>
          </Text>
        </Wrapper>

        <Wrapper alignItems="center" gap={16}>
          <Text color="dark" isSmall>
            {t`Contact us on`}
            {' '}
            <Link href={whatsAppSupport} target="_blank" color="dark" isSmall>
              WhatsApp
            </Link>{' '}
            {t`or`}
            {' '}
            <Link
              href="https://t.me/whatcrmbot"
              target="_blank"
              color="dark"
              isSmall
            >
              Telegram
            </Link>
          </Text>

          {lang == 'ru' && (
            <Link
              href="https://t.me/whatcrm_news"
              target="_blank"
              color="dark"
              isSmall
            >
              {t`Stay tuned for project news`}
            </Link>
          )}

          <>
            <Link href={licenseAgreement} target="_blank" color="dark" isSmall>
              {t`License agreement`}
            </Link>

            <Link color="dark" href={privacyPolicy} target="_blank" isSmall>
              {t`Privacy policy`}
            </Link>
          </>
        </Wrapper>
      </Wrapper>
    </footer>
  );
};

export default Footer;
