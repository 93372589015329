import React, {useState, useEffect} from 'react';
import {t} from 'ttag';
import Fuse from 'fuse.js';

import Advice from 'components/advice/advice';
import Table, {
  TableHead,
  TableBody,
  TableCell
} from '../../../../components/table/table.jsx';
import Link from '../../../../components/link/link.jsx';
import Button from '../../../../components/button/button';
import Icons from '../../../../components/icons/icons.jsx';
import Preloader from '../../../../components/preloader/preloader.jsx';
import EmptyBox from '../../../../components/empty-box/empty-box.jsx';
import {Input} from 'components/form/form.jsx';
import {useAppContext} from '../../../../app-context.js';
import {useRequest} from '../../../../common/hooks';
import {getCustomerListReq} from '../../../../common/api/api.js';

import Customer from './customer/customer.jsx';
import Creating from './creating/creating.jsx';
import CustomerListContext from './customer-list-context.js';
import Wrapper from 'components/wrapper/wrapper.jsx';

const CustomerList = () => {
  const {user, lang} = useAppContext();

  const [search, setSearch] = useState('');
  const [customerList, setCustomerList] = useState();
  const [isCreatingOpen, setIsCreatingOpen] = useState(false);

  const {request} = useRequest();

  const getCustomerList = async () => {
    const res = await request(getCustomerListReq(user.id));
    setCustomerList(Object.values(res));
  };

  useEffect(() => {
    getCustomerList();
  }, []);

  const amocrmIcon =
    lang === 'ru' ? '/static/img/crm/amocrm.svg' : '/static/img/crm/kommo.svg';

  const crmList = {
    LK: {name: t`Personal account`, icon: '/static/img/crm/whatcrm.svg'},
    B24: {
      name: `${t`Bitrix24`} WhatsApp`,
      icon: '/static/img/crm/bitrix24.svg'
    },
    TELPHGRAM: {
      name: `${t`Bitrix24`} Telegram`,
      icon: '/static/img/crm/bitrix24.svg'
    },
    AMO: {name: `${t`Kommo`} WhatsApp`, icon: amocrmIcon},
    TELEGRAM: {name: `${t`Kommo`} Telegram`, icon: amocrmIcon},
    MONDAY: {name: 'monday WhatsApp', icon: '/static/img/crm/monday.svg'},
    TGMONDAY: {name: 'monday Telegram', icon: '/static/img/crm/monday.svg'},
    AVITO: {name: `${t`Kommo`} ${t`Avito`}`, icon: '/static/img/crm/avito.svg'},
    AVITOBX: {
      name: `${t`Bitrix24`} ${t`Avito`}`,
      icon: '/static/img/crm/avito.svg'
    },
    PIPEDRIVE: {name: 'Pipedrive', icon: '/static/img/crm/pipedrive.svg'}
  };

  const fuse = new Fuse(customerList, {keys: ['domain'], threshold: 0.25});

  const fusedCustomerList = search
    ? fuse.search(search).map(item => item.item)
    : customerList;

  return (
    <CustomerListContext.Provider
      value={{
        customerList,
        crmList,
        setCustomerList
      }}
    >
      <div>
        <Advice style={{marginBottom: 16}}>
          <Link href="https://wa.me/79993335628" target="_blank">
            {t`Write to the Partner Relationship Department`}
          </Link>
        </Advice>

        <Wrapper gap={16} style={{marginBottom: 16}}>
          <Button onClick={() => setIsCreatingOpen(true)}>
            <Icons.Plus />
            {t`Add a customer`}
          </Button>

          <Input
            value={search}
            onChange={setSearch}
            placeholder={t`Search`}
            isSearch
          />
        </Wrapper>

        {customerList ? (
          fusedCustomerList?.length > 0 ? (
            <Table>
              <TableHead>
                <TableCell>{t`Domain`}</TableCell>
                <TableCell>{t`Type`}</TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
              </TableHead>

              <TableBody>
                {fusedCustomerList.map(item => (
                  <Customer key={item.id} customer={item} />
                ))}
              </TableBody>
            </Table>
          ) : (
            <EmptyBox />
          )
        ) : (
          <Preloader />
        )}

        {isCreatingOpen && (
          <Creating onClose={() => setIsCreatingOpen(false)} />
        )}
      </div>
    </CustomerListContext.Provider>
  );
};

export default CustomerList;
