import React from 'react';
import {Formik, Form} from 'formik';
import {t} from 'ttag';
import update from 'immutability-helper';

import Preloader from 'components/preloader/preloader';
import Button, {SaveButton} from 'components/button/button';
import Line from 'components/line/line';
import Wrapper from 'components/wrapper/wrapper';
import {useConnectionListContext} from 'components/connection-list/connection-list-context';
import {useSettingsContext} from '../../settings-context';
import PipelineId from './pipeline-id/pipeline-id';
import StageId from './stage-id/stage-id';
import Strategy from './strategy/strategy';
import ResponsibleId from './responsible-id/responsible-id';
import IsGroup from './is-group/is-group';
import {useRequest} from 'common/hooks';
import {useConnectionContext} from 'components/connection-list/connection/connection-context';
import {putConnection} from 'common/api/pipedrive';
import {useAppContext} from 'app-context';
import CreateNewIfClose from './create-new-if-close/create-new-if-close';
import WriteFirst from './write-first/write-first';
import MessagingNotify from './messaging-notify/messaging-notify';

const Pipedrive = () => {
  const {setNotificationList} = useAppContext();
  const {client, setSettingsId} = useConnectionListContext();
  const {connection} = useConnectionContext();
  const {connectionConfig, updateConnectionConfig} = useSettingsContext();

  const {crmRequest} = useRequest();

  const handleSubmit = async values => {
    const res = await crmRequest({
      ...putConnection(client.domain, connection.chat_key, values),
      crm: 'PIPEDRIVE'
    });

    if (!res.success) return true;

    setNotificationList(prevValue =>
      update(prevValue, {
        $push: [
          {
            title: connection.label || `${t`Connection`} ${connection.id}`,
            text: t`The settings have been successfully saved.`
          }
        ]
      })
    );

    updateConnectionConfig(values);
    setSettingsId(-1);

    return true;
  };

  return (
    <>
      {connectionConfig ? (
        <Formik initialValues={connectionConfig} onSubmit={handleSubmit}>
          <Form>
            <Wrapper gap={16} isColumn>
              <PipelineId />
              <StageId />
              <Strategy />
              <ResponsibleId />

              <Wrapper gap={8} isColumn>
                <IsGroup />
                <MessagingNotify />
                <CreateNewIfClose />
                <WriteFirst />
              </Wrapper>
            </Wrapper>

            <Line />

            <Wrapper>
              <SaveButton>{t`Save`}</SaveButton>

              <Button color="white" onClick={() => setSettingsId(-1)}>
                {t`Cancel`}
              </Button>
            </Wrapper>
          </Form>
        </Formik>
      ) : (
        <Preloader isMin />
      )}
    </>
  );
};

export default Pipedrive;
