import React, {useState, useEffect} from 'react';
import {t, c} from 'ttag';
import {v4 as uuid} from 'uuid';
import axios from 'axios';
import update from 'immutability-helper';

import {useAppContext} from '../../app-context.js';
import {inflectNumberal} from '../actions.js';

export const useRequest = () => {
  const {user, lang, setNotificationList} = useAppContext();

  const baseURL =
    window.location.host.slice(-2) == 'ru'
      ? process.env.REACT_APP_API_RU
      : process.env.REACT_APP_API;

  const instance = axios.create({
    baseURL,
    headers: {'X-Whappim-Token': process.env.REACT_APP_API_TOKEN}
  });

  const amoBaseUrl = window.location.host.endsWith('ru')
    ? 'https://waamo.whatcrm.ru'
    : 'https://amocrm.whatcrm.net';

  const amoInstance = axios.create({
    baseURL: amoBaseUrl,
    headers: {'X-Amo-Token': 'e93e75f097f9b3317c4343e816069e6a'}
  });

  const avitoInstance = axios.create({
    baseURL: 'https://avitoamo.whatcrm.net',
    headers: {'X-Api-Token': 'b5b3404abf5a47359429d622b83e7e36'}
  });

  const avitoBxInstance = axios.create({
    baseURL: 'https://dev.avito.whatcrm.ru',
    headers: {'X-Api-Token': '30a684e1e2efda184f856f8ee41f4f83'}
  });

  const yclInstance = axios.create({
    baseURL: user.ref ? `https://${user.ref}` : 'https://dev.avito.whatcrm.net',
    headers: {'X-Api-Token': '3cba9ad34d734d9a30ce822054de8878'}
  });

  const montgInstance = axios.create({
    baseURL: process.env.REACT_APP_MONTG,
    headers: {'X-Monday-Token': '35b6e76e7407cbc0544b4092d7492ef9'}
  });

  const monwaInstance = axios.create({
    baseURL: process.env.REACT_APP_MONWA,
    headers: {'X-Monday-Token': '35b6e76e7407cbc0544b4092d7492ef9'}
  });

  const pipedriveInstance = axios.create({
    baseURL: process.env.REACT_APP_PIPEDRIVE
  });

  const telegramBaseUrl = window.location.host.endsWith('ru')
    ? 'https://tgamo.whatcrm.ru'
    : 'https://telegram.whatcrm.ru';

  const telegramInstance = axios.create({
    baseURL: telegramBaseUrl,
    headers: {'X-Amo-Token': 'e93e75f097f9b3317c4343e816069e6a'}
  });

  const b24BaseURL =
    process.env.REACT_APP_ENV == 'rocket'
      ? 'https://wa.rocket.whatcrm.net'
      : process.env.REACT_APP_ENV == 'dev'
        ? 'https://dev.whatsapp.whatcrm.ru'
        : user.widget_code == 'bitrix_com'
          ? 'https://b24whatsapp.whatcrm.net'
          : user.widget_code == 'bitrix_ru'
            ? 'https://wab24.whatcrm.ru'
            : 'https://bitrix24.whatcrm.net';

  const b24Instance = axios.create({
    baseURL: b24BaseURL,
    headers: {
      'X-Api-Token':
        process.env.REACT_APP_ENV == 'dev'
          ? '30a684e1e2efda184f856f8ee41f4f83'
          : 'b3e18c7fcaa2ff6a4ec2b6f55dd29399'
    }
  });

  const telphgramBaseURL =
    process.env.REACT_APP_ENV == 'rocket'
      ? 'https://tg.rocket.whatcrm.net'
      : process.env.REACT_APP_ENV == 'dev'
        ? 'https://dev.telegram.whatcrm.ru'
        : user.widget_code == 'bitrixtg_com'
          ? 'https://b24telegram.whatcrm.net'
          : user.widget_code == 'bitrixtg_ru'
            ? 'https://tgb24.whatcrm.ru'
            : 'https://telphgram.whatcrm.net';

  const telphgramInstance = axios.create({
    baseURL: telphgramBaseURL,
    headers: {'X-Api-Token': 'b3e18c7fcaa2ff6a4ec2b6f55dd29399'}
  });

  const dadataInstance = axios.create({
    baseURL: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/',
    headers: {Authorization: 'Token 4f953f109625e5f078a034325f7df25c15ba29ef'},
    method: 'POST'
  });

  const errorKeys = ['error', 'error_code', 'error_text'];

  const notify = ({title = t`Error`, text = t`Something went wrong.`}) =>
    setNotificationList(prevValue =>
      update(prevValue, {
        $push: [{title, text}]
      })
    );

  const getFormData = data => {
    const formData = new FormData();

    Object.entries(data).map(([key, value]) => {
      const uniq = uuid().replaceAll('-', '_');
      const filename = `${uniq}_${value.name}`;

      formData.append(key, value, filename);
    });

    return formData;
  };

  const request = async ({
    path,
    params = {},
    method = 'get',
    data = {},
    isFormData = false,
    url = undefined
  }) => {
    const formData = isFormData ? getFormData(data) : data;

    const adminParams =
      user.crm == 'LK' ? {} : {user_id: user.user_id, is_admin: user.is_admin};

    const baseURL =
      window.location.host.slice(-2) == 'ru'
        ? process.env.REACT_APP_API_RU
        : process.env.REACT_APP_API;

    const instance = axios.create({
      baseURL: url || baseURL,
      headers: {'X-Whappim-Token': process.env.REACT_APP_API_TOKEN}
    });

    const res = await instance(path, {
      method,
      params: {...adminParams, ...params, lang},
      data: formData
    })
      .then(res => res.data)
      .then(res => {
        const resKeys = Object.keys(res);

        const isError =
          resKeys.findIndex(item => errorKeys.includes(item)) !== -1;

        if (isError) {
          if (res.error !== 'timeout') {
            notify({text: res.error_text || res.error});
          }

          return {};
        }

        return res;
      })
      .catch(e => {
        notify({
          text:
            e?.response?.data?.error_text ||
            e?.response?.data?.message ||
            `${e.name}, ${e.message}`
        });

        return {};
      });

    return res.data || res;
  };

  const getErrorText = error_code => {
    if (error_code == 'E01') return t`Widget installation error`;
    else if (error_code == 'E02' || error_code == 'E02R')
      return t`The widget is not installed`;
    else if (error_code == 'E03' || error_code == 'E03R')
      return t`Invalid token, need to reinstall the widget`;
    else if (error_code == 'E04' || error_code == 'E04R')
      return t`Kommo account requires payment`;
    else if (error_code.slice(-1) == 'R')
      return t`Need to reinstall the widget`;

    return null;
  };

  const crmRequest = async ({
    crm,
    data = {},
    domain,
    method = 'get',
    noNotice,
    params = {},
    path
  }) => {
    const crmInstance =
      crm == 'AVITO'
        ? avitoInstance
        : crm == 'AVITOBX'
          ? avitoBxInstance
          : crm === 'B24'
            ? b24Instance
            : crm === 'AMO'
              ? amoInstance
              : crm === 'TELEGRAM'
                ? telegramInstance
                : crm === 'TELPHGRAM'
                  ? telphgramInstance
                  : crm === 'MONDAY'
                    ? monwaInstance
                    : crm == 'PIPEDRIVE'
                      ? pipedriveInstance
                      : crm == 'TGMONDAY'
                        ? montgInstance
                        : crm == 'YCL'
                          ? yclInstance
                          : instance;

    const res = await crmInstance(path, {
      method,
      params,
      data
    })
      .then(response => response.data)
      .then(res => {
        const isMondayError =
          (crm == 'MONDAY' || crm == 'TGMONDAY') &&
          (('success' in res && !res.success) ||
            ('result' in res && !res.result));

        if (isMondayError && !noNotice) {
          notify({
            text:
              typeof res.result == 'string'
                ? res.result
                : t`Something went wrong.`
          });
        }

        if (res.success) {
          return res.data || res;
        }

        if (res.error && res.error !== 'timeout' && !noNotice) {
          const errorText = getErrorText(res.error_code);

          notify({
            title: domain || undefined,
            text: errorText ? `${errorText}.` : res.error_text || res.error
          });

          return domain && errorText ? {error_text: errorText} : {};
        }

        return res;
      })
      .catch(function (error) {
        if (!noNotice) {
          notify({
            title: domain || undefined,
            text:
              error?.response?.data?.error ||
              error?.response?.data?.message ||
              error.message
          });
        }
      });

    return res || {};
  };

  const dadataRequest = async ({path, data}) => {
    const res = await dadataInstance(path, {data: {count: 20, ...data}});
    return res.data.suggestions;
  };

  return {request, crmRequest, dadataRequest};
};

export const useWithIcon = ref => {
  const [withIcon, setWithIcon] = useState();

  useEffect(() => {
    setWithIcon(!!ref.current.querySelector('.icon'));
  }, []);

  return withIcon;
};

export const useCopyToClipboard = () => {
  const {setNotificationList} = useAppContext();

  const pushNotification = title =>
    setNotificationList(prevValue =>
      update(prevValue, {
        $push: [{title}]
      })
    );

  const copyToClipboard = text =>
    navigator.clipboard
      .writeText(text)
      .then(() => pushNotification(t`Copied to the clipboard`))
      .catch(() => {
        const input = document.createElement('input');
        input.value = text;

        document.body.appendChild(input);

        input.focus();
        input.select();

        const success = document.execCommand('copy');

        document.body.removeChild(input);

        pushNotification(
          success
            ? t`Copied to the clipboard`
            : t`Failed to copy to the clipboard`
        );
      });

  return copyToClipboard;
};

export const useOnKeyDown = ({onEnter = () => {}, onEscape = () => {}}) => {
  const callback = ({keyCode}) => {
    switch (keyCode) {
      case 13:
        onEnter();
        break;
      case 27:
        onEscape();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', callback);
    return () => document.removeEventListener('keydown', callback);
  }, []);
};

export const useOnClickOutside = ({
  ref = {},
  exception = {},
  onClick = () => {}
}) => {
  const callback = e => {
    if (
      !ref.current ||
      ref.current.contains(e.target) ||
      (exception.current &&
        (exception.current === e.target ||
          exception.current.contains(e.target)))
    ) {
      return;
    }

    onClick(e);
  };

  useEffect(() => {
    document.addEventListener('mouseup', callback);
    return () => document.removeEventListener('mouseup', callback);
  }, []);
};

export const useGetTariffName = () => {
  const {lang} = useAppContext();

  const isSlavic = lang === 'ru' || lang === 'uk';

  const getTariffName = tariff => {
    const quantity = tariff.quantity || 30;
    const period = tariff.period || 'day';

    return (
      <>
        {quantity}
        {' '}

        {isSlavic
          ? inflectNumberal({
              value: quantity,
              labels:
                lang === 'ru'
                  ? period === 'day'
                    ? ['день', 'дня', 'дней']
                    : ['месяц', 'месяца', 'месяцев']
                  : lang === 'uk' &&
                    (period === 'day'
                      ? ['день', 'дні', 'днів']
                      : ['місяць', 'місяці', 'місяців'])
            })
          : period === 'day'
            ? t`days`
            : t`months`}
      </>
    );
  };

  return getTariffName;
};

export const useGetTariffPlan = () => {
  const {lang} = useAppContext();

  const getTariffPlan = ({id, quantity}) => (
    <>
      {id === 3 ? 'Enterprise' : id === 2 ? 'Pro' : 'Start'}

      {quantity > 1 && (
        <>
          {' '}
          {c('quantity').t`for`} {quantity}
          {' '}
          {lang === 'ru'
            ? 'подключений'
            : lang === 'uk'
              ? 'підключень'
              : t`connections`}
        </>
      )}
    </>
  );

  return getTariffPlan;
};

export {default as useWebSocket} from './use-websocket.js';
export {default as useCheckerRequest} from './use-checker-request';
export {default as usePaymentHistory} from './use-payment-history';
export {default as useSubscriptionList} from './use-subscription-list';
