import React, {useState, useEffect} from 'react';
import {t} from 'ttag';
import QRCode from 'qrcode';
import * as Title from '../../../../title/title.jsx';
import Article from '../../../../article/article.jsx';
import Text from '../../../../text/text.jsx';
import Wrapper from '../../../../wrapper/wrapper.jsx';
import QR from '../../../../qr/qr.jsx';
import Link from '../../../../link/link.jsx';
import {useAppContext} from '../../../../../app-context.js';
import Preloader from 'components/preloader/preloader.jsx';
import EmptyBox from 'components/empty-box/empty-box.jsx';
import Icons from 'components/icons/icons.jsx';

const Notifications = () => {
  const {employeeList, user} = useAppContext();

  const [manager, setManager] = useState();
  const [qr, setQr] = useState();

  const getManager = () => {
    let newManager = employeeList.find(
      manager => manager.crm_user_id == user.user_id
    );

    if (newManager) return setManager(newManager);

    newManager = employeeList.find(manager => manager.email == user.domain);
    setManager(newManager || null);
  };

  useEffect(() => {
    if (!manager) return;

    QRCode.toDataURL(`https://t.me/whatcrm_bot?start=${manager.ukey}`).then(
      res => setQr(res || null)
    );
  }, [manager]);

  useEffect(() => {
    getManager();
  }, []);

  return (
    <>
      <Title.H3 style={{marginBottom: 8}}>
        {t`Notifications in Telegram`}
      </Title.H3>

      <Article style={{marginBottom: 16}}>
        <Text>{t`Set up the bot to receive important notifications about your connections.`}</Text>
      </Article>

      {typeof manager == 'object' ? (
        manager ? (
          typeof qr != 'undefined' ? (
            qr ? (
              <Wrapper alignItems="center" gap={0} isColumn>
                {manager.telegram_id > 0 && (
                  <Wrapper alignItems="center" gap={0}>
                    <Icons.Check />
                    <Text color="dark">{t`Telegram bot is connected`}</Text>
                  </Wrapper>
                )}

                <QR qr={qr} />

                <Link
                  href={`https://t.me/whatcrm_bot?start=${manager.ukey}`}
                  target="_blank"
                >
                  {'t.me/whatcrm_bot'}
                </Link>
              </Wrapper>
            ) : (
              <EmptyBox title={t`Error`}>
                <Text>{t`Couldn’t find your unique key.`}</Text>
              </EmptyBox>
            )
          ) : (
            <Preloader />
          )
        ) : (
          <EmptyBox title={t`Error`}>
            <Text>{t`Couldn't identify you.`}</Text>
          </EmptyBox>
        )
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default Notifications;
