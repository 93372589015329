import React, {useState} from 'react';
import {t} from 'ttag';

import {useConnectionContext} from '../../connection-context';
import Text from 'components/text/text';
import Wrapper from 'components/wrapper/wrapper';
import Button from 'components/button/button';
import Icons from 'components/icons/icons';

import PlaceForm from './place-form/place-form';

const Place = () => {
  const {connection} = useConnectionContext();
  const [isActive, setIsActive] = useState(false);

  const isVisible =
    connection.condition == 'premium' ||
    (connection.condition == 'paid' && connection.tariff_plane == 'Enterprise');

  const places = {
    Finland: `🇫🇮 ${t`Finland`}`,
    Germany: `🇩🇪 ${t`Germany`}`,
    Russian: `🇷🇺 ${t`Russia`}`
  };

  return (
    <>
      {connection.place && isVisible && (
        <Wrapper alignItems="center" gap={0}>
          <Text>{`${t`Server location`}: ${places[connection.place] || connection.place}`}</Text>

          <Button
            color="transparent"
            style={{height: 16}}
            onClick={() => setIsActive(true)}
          >
            <Icons.Pencil />
          </Button>
        </Wrapper>
      )}

      {isActive && <PlaceForm setIsActive={setIsActive} />}
    </>
  );
};

export default Place;
