export const fetchPrefs = (domain, id) => ({
  path: `v1/prefs/${domain}`,
  params: {t: 'get_user_phone', id}
});

export const postPrefs = (domain, id, data) => ({
  path: `v1/prefs/${domain}`,
  params: {t: 'set_pref', id},
  method: 'post',
  data
});
