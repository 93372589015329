import React, {useState, useEffect} from 'react';
import {t} from 'ttag';

import * as Title from 'components/title/title.jsx';
import Advice from 'components/advice/advice';
import Button from 'components/button/button';
import EmptyBox from 'components/empty-box/empty-box.jsx';
import Popup from 'components/popup/popup.jsx';
import Preloader from 'components/preloader/preloader.jsx';
import Text from 'components/text/text.jsx';
import Wrapper from 'components/wrapper/wrapper.jsx';
import {useAppContext} from 'components/../app-context.js';
import {useRequest} from 'components/../common/hooks';
import {triggerReviewReq} from 'common/api/api.js';
import * as AppEnv from 'app-env';

import WarningList from './warning-list/warning-list';
import TariffPlan from './tariff-plan/tariff-plan';
import PaymentContext, {Client} from './payment-context';

interface Payment {
  client: Client;
  connection?: Partial<AppEnv.Connection>;
  connectionIdList?: number[];
  connectionList?: AppEnv.Connection[];
  isTariffChanging: boolean;
  onClose: () => void;
  tariffList: AppEnv.Tariffs | undefined;
}

const Payment = ({
  client,
  connection,
  connectionIdList,
  connectionList,
  isTariffChanging,
  onClose,
  tariffList
}: Payment) => {
  const {user} = useAppContext();

  const [selectedTariffId, setSelectedTariffId] = useState(
    connection?.tariff_id
  );
  const [isFeatureListOpen, setIsFeatureListOpen] = useState(false);

  const {request} = useRequest();

  useEffect(() => {
    if (tariffList && Object.keys(tariffList).length > 0) {
      const tariffIdList = Object.entries(tariffList)
        .map(entry => Object.keys(entry[1]))
        .flat();

      if (
        !selectedTariffId ||
        tariffIdList.indexOf(selectedTariffId.toString()) === -1
      ) {
        let tariff =
          Object.values(tariffList.Pro || {}).find(
            item => item.quantity == 3
          ) || {};

        if (!Object.keys(tariff).length) {
          tariff =
            Object.values(tariffList.Pro || {}).find(
              item => item.quantity == 30
            ) || {};
        }

        setSelectedTariffId(tariff.id);
      }
    }
  }, [tariffList]);

  const openReviewPage = async () => {
    await request(triggerReviewReq(user.id));

    let url;

    if (user.crm == 'AMO' || user.crm == 'TELEGRAM' || user.crm == 'AVITO') {
      const anchor =
        user.crm === 'AMO'
          ? 'amo_wapp_im'
          : user.crm == 'AVITO'
            ? 'avito_whatcrm'
            : 'telphgram';

      url = `https://${user.domain}/amo-market#${anchor}`;
    } else if (user.crm == 'B24') {
      if (user.widget_code != 'bitrix_ru')
        url = `https://${user.domain}/market/detail/whatcrm.whatsapp_by_whatcrm/`;
      else if (user.domain.endsWith('.kz'))
        url = `https://${user.domain}/market/detail/telphin.whatsapp_by_whatcrm/`;

      url = `https://${user.domain}/market/detail/telphin.whatsapp_ot_whatcrm/`;
    } else if (user.crm == 'TELPHGRAM') {
      if (user.widget_code != 'bitrixtg_ru')
        url = `https://${user.domain}/market/detail/whatcrm.telegram_by_whatcrm/`;
      else if (user.domain.endsWith('.kz'))
        url = `https://${user.domain}/market/detail/telphin.telegram_by_whatcrm/`;

      url = `https://${user.domain}/market/detail/telphin.telegram_ot_whatcrm/`;
    }

    window.open(url, '_blank');
  };

  const isAdviceShown =
    !user.is_partner &&
    user.is_review !== 2 &&
    ((user.crm === 'AMO' && user.domain.slice(-4) !== '.com') ||
      (user.crm === 'AVITO' && user.domain.slice(-4) !== '.com') ||
      user.crm == 'B24' ||
      (user.crm === 'TELEGRAM' && user.domain.slice(-4) !== '.com') ||
      user.crm == 'TELPHGRAM');

  const width = Object.keys(tariffList || {}).length > 2 ? 1080 : 790;

  return (
    <PaymentContext.Provider
      value={{
        client,
        closePayment: onClose,
        connection,
        connectionIdList,
        connectionList,
        isFeatureListOpen,
        selectedTariffId,
        setIsFeatureListOpen,
        setSelectedTariffId,
        tariffList
      }}
    >
      <Popup width={width} onClick={() => onClose()}>
        <Title.H1 style={{marginBottom: 16}}>
          {isTariffChanging ? t`Change tariff plan` : t`Choose a tariff plan`}
        </Title.H1>

        <Wrapper gap={16} isColumn>
          {isAdviceShown && (
            <Advice type="info" style={{width: '100%'}}>
              <Wrapper
                alignItems="flex-end"
                gap={16}
                justifyContent="space-between"
                mobileWrap
              >
                <div>
                  <Title.H3 style={{marginBottom: 8}}>
                    {t`Give feedback and get a discount`}
                  </Title.H3>

                  <Text color="dark">
                    {t`Your feedback will help us to improve the service and provide you with an even better experience.`}
                  </Text>
                </div>

                <Button color="white" onClick={openReviewPage}>
                  {t`Give feedback`}
                </Button>
              </Wrapper>
            </Advice>
          )}

          <WarningList />

          {tariffList ? (
            Object.keys(tariffList).length > 0 ? (
              <Wrapper alignItems="stretch" gap={24}>
                {/* {tariffList.Start && (
                  <TariffPlan tariffOptions={Object.values(tariffList.Start)} />
                )}

                {tariffList.Pro && (
                  <TariffPlan tariffOptions={Object.values(tariffList.Pro)} />
                )} */}

                {Object.values(tariffList).map((item, i) => (
                  <TariffPlan key={i} tariffOptions={Object.values(item)} />
                ))}
              </Wrapper>
            ) : (
              <EmptyBox />
            )
          ) : (
            <Preloader />
          )}
        </Wrapper>
      </Popup>
    </PaymentContext.Provider>
  );
};

export default Payment;
