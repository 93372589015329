import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import {Toggle} from '../../../../../../form/form.jsx';
import Wrapper from 'components/wrapper/wrapper.jsx';
import Tooltip from 'components/tooltip/tooltip.jsx';
import Text from 'components/text/text.jsx';

const ShowChatInTimeline = () => {
  const [field, , helpers] = useField('show_chat_in_timeline');
  const {value} = field;
  const {setValue} = helpers;

  return (
    <Wrapper alignItems="center">
      <Toggle
        label={t`Message history in card timeline`}
        isChecked={value}
        onChange={setValue}
      />

      <Tooltip>
        <Text style={{marginBottom: 4}}>
          {t`Messages are displayed in CRM entities according to the following priority:`}
        </Text>

        <Text style={{marginBottom: 4}}>
          <Text isBold isSpan>{t`Lead only`}</Text>
          {' — '}
          {t`messages are saved in the lead’s card.`}
        </Text>

        <Text style={{marginBottom: 4}}>
          <Text isBold isSpan>{t`Lead is linked to a contact`}</Text>
          {' — '}
          {t`messages are moved to the contact’s card.`}
        </Text>

        <Text style={{marginBottom: 4}}>
          <Text isBold isSpan>{t`Contact has a deal`}</Text>
          {' — '}
          {t`messages remain in the contact card.`}
        </Text>
      </Tooltip>
    </Wrapper>
  );
};

export default ShowChatInTimeline;
