import React from 'react';
import {t} from 'ttag';
import cn from 'classnames';

import {Input} from 'components/form/form';
import * as Title from 'components/title/title.jsx';
import Button from 'components/button/button';
import Image from 'components/image/image.jsx';
import Line from 'components/line/line.jsx';
import Link from 'components/link/link.jsx';
import Popup from 'components/popup/popup.jsx';
import Text from 'components/text/text.jsx';
import Wrapper from 'components/wrapper/wrapper.jsx';
import {useAppContext} from 'app-context.js';
import {usePaymentContext} from 'components/payment/payment-context';
import {useTransitionContext} from '../transition-context';
import * as AppEnv from 'app-env';

interface Method {
  isCloudpaymentsShown: boolean;
  isTelphinShown: boolean;
  onClick: (arg: boolean) => void;
  setMethod: AppEnv.SetState<'cloud' | 'stripe' | 'telphin' | undefined>;
  promoCode: string;
  setPromoCode: AppEnv.SetState<string>;
}

const Method = ({
  isCloudpaymentsShown,
  isTelphinShown,
  onClick,
  setMethod,
  promoCode,
  setPromoCode
}: Method) => {
  const {setIsTelphinClientFormOpen, user} = useAppContext();
  const {closePayment} = usePaymentContext();
  const {method} = useTransitionContext();

  const handleClick = () => {
    closePayment();
    setIsTelphinClientFormOpen(true);
  };

  const isAccountingWarningShown =
    !!method && method != 'telphin' && !!user.telphin_code;

  return (
    <Popup onClick={() => onClick(false)}>
      <Title.H1 style={{marginBottom: 16}}>{t`Payment method`}</Title.H1>

      <Wrapper gap={32} style={{marginBottom: 16}} isColumn>
        <Wrapper gap={16} isColumn>
          {isCloudpaymentsShown && (
            <div
              className={cn('method', {
                method_selected: method === 'cloud'
              })}
              onClick={() => setMethod('cloud')}
            >
              <Image
                src="static/img/icons/wallet.svg"
                style={{marginBottom: 24}}
              />

              <Title.H2>{`Российской картой онлайн`}</Title.H2>
            </div>
          )}

          <div
            className={cn('method', {
              method_selected: method === 'stripe'
            })}
            onClick={() => setMethod('stripe')}
          >
            <Image
              src="static/img/icons/wallet.svg"
              style={{marginBottom: 24}}
            />

            <Title.H2>{t`By card online`}</Title.H2>
          </div>

          {isTelphinShown && (
            <>
              <div
                className={cn('method', {
                  method_selected: method === 'telphin',
                  method_disabled: !user.telphin_code
                })}
                onClick={() => setMethod('telphin')}
              >
                <Image
                  src="static/img/icons/telphin-cabinet.svg"
                  style={{marginBottom: 24}}
                />

                <Title.H2>{t`From the Telphin personal account balance`}</Title.H2>
              </div>

              {!user.telphin_code && (
                <Text>
                  {`Чтобы оплатить с баланса личного кабинета «Телфин» необходимо `}
                  <Link isFake onClick={handleClick}>
                    {`заполнить реквизиты`}
                  </Link>
                  {`.`}
                </Text>
              )}
            </>
          )}
        </Wrapper>

        {isAccountingWarningShown && (
          <div>
            <Text style={{marginBottom: 8}}>
              {`Платеж картой будет считаться оплатой от физического лица и не отобразится в бухгалтерском акте сверки.`}
            </Text>

            <Text>
              {`Чтобы платеж попал в акт, необходимо выбрать способ оплаты «`}
              <Text isBold isSpan>
                {`С баланса личного кабинета „Телфин“`}
              </Text>
              {`».`}
            </Text>
          </div>
        )}

        <Wrapper isColumn gap={8}>
          <Input
            isMax
            name="promo_code"
            onChange={setPromoCode}
            placeholder={t`Promo code`}
            value={promoCode}
            isDisabled={method == 'telphin'}
          />

          {method == 'telphin' && (
            <Text>{`Промокод не применяется при оплате с баланса личного кабинета «Телфин».`}</Text>
          )}
        </Wrapper>
      </Wrapper>

      <Line />

      <Wrapper alignItems="center">
        <Button isDisabled={!method} onClick={() => onClick(true)}>
          {t`Continue`}
        </Button>

        <Button color="white" onClick={() => onClick(false)}>
          {t`Cancel`}
        </Button>
      </Wrapper>
    </Popup>
  );
};

export default Method;
