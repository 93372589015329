import React from 'react';
import {t} from 'ttag';

import Wrapper from '../../wrapper/wrapper';
import Advice from '../../advice/advice';
import Link from '../../link/link';
import {useAppContext} from '../../../app-context';
import {useConnectionListContext} from '../connection-list-context';

const Advices = () => {
  const {lang} = useAppContext();
  const {client, isConnector} = useConnectionListContext();

  const isAdvicesShown = !client.isCustomer && !isConnector;

  return (
    <>
      {isAdvicesShown && (
        <Wrapper>
          {['AMO', 'B24', 'LK', 'MONDAY', 'PIPEDRIVE'].includes(client.crm) &&
            lang == 'ru' && (
              <Advice>
                <Link
                  href="https://whatcrm.gitbook.io/whatcrm-docs/poleznye-stati/dlya-whatsapp/massovye-rassylki-cherez-whatsapp-vazhnye-ogranicheniya-i-rekomendacii"
                  target="_blank"
                >
                  {'🚨 Внимание: массовые рассылки'}
                </Link>
              </Advice>
            )}

          {client.crm == 'PIPEDRIVE' && lang != 'ru' && (
            <>
              <Advice>
                <Link
                  href="https://whatcrm.gitbook.io/whatcrm-docs/v/english/integrations-products/pipedrive"
                  target="_blank"
                >
                  {'✅ '}
                  {t`Service setup guide`}
                </Link>
              </Advice>

              <Advice>
                <Link
                  href="https://whatcrm.gitbook.io/whatcrm-docs/v/english/useful-articles/important-recommendations-when-using-whatcrm"
                  target="_blank"
                >
                  {'💡 '}
                  {t`Recommendations for using WhatsApp`}
                </Link>
              </Advice>

              <Advice>
                <Link
                  href="https://whatcrm.gitbook.io/whatcrm-docs/v/english/integrations-products/pipedrive/automated-whatsapp-messaging-with-pipedrive-triggers"
                  target="_blank"
                >
                  {'🤖 '}
                  {t`How to set up automatic messages`}
                </Link>
              </Advice>
            </>
          )}

          {['MONDAY', 'TGMONDAY'].includes(client.crm) && (
            <Advice>
              <Link
                href={
                  client.crm == 'MONDAY'
                    ? 'https://whatcrm.gitbook.io/whatcrm-docs/v/english/integrations-products/monday.com/installing-the-widget-and-connecting-the-number'
                    : 'https://whatcrm.gitbook.io/whatcrm-docs/v/english/integrations-products/monday.com/telegram'
                }
                target="_blank"
              >
                {'✅ '}
                {t`Service setup guide`}
              </Link>
            </Advice>
          )}

          {client.crm == 'B24' && lang != 'ru' && (
            <Advice>
              <Link
                href="https://youtu.be/UbGB941qLIs?si=uycjMXDpCsbZPiTR"
                target="_blank"
              >
                {'📼 '}
                {t`Connection video guide`}
              </Link>
            </Advice>
          )}

          {['AMO', 'B24', 'LK', 'MONDAY'].includes(client.crm) && (
            <>
              <Advice>
                <Link
                  href={
                    lang == 'ru'
                      ? 'https://whatcrm.gitbook.io/whatcrm-docs/poleznye-stati/dlya-whatsapp/rekomendacii-po-predotvrasheniyu-blokirovki-whatsapp'
                      : 'https://whatcrm.gitbook.io/whatcrm-docs/v/english/useful-articles/important-recommendations-when-using-whatcrm'
                  }
                  target="_blank"
                >
                  {'💡 '}
                  {t`Recommendations for using WhatsApp`}
                </Link>
              </Advice>

              {lang == 'ru' && (
                <Advice>
                  <Link
                    href="https://whatcrm.gitbook.io/whatcrm-docs/poleznye-stati/dlya-whatsapp/sovety-po-stabilnoi-rabote-whatsapp"
                    target="_blank"
                  >
                    {'✅ '}
                    {'Стабильная работа WhatsApp'}
                  </Link>
                </Advice>
              )}
            </>
          )}

          {['TELEGRAM', 'TELPHGRAM', 'LK', 'TGMONDAY'].includes(client.crm) && (
            <>
              {lang == 'ru' && (
                <Advice>
                  <Link
                    href="https://whatcrm.gitbook.io/whatcrm-docs/poleznye-stati/dlya-telegram/minimizaciya-riska-blokirovki-v-telegram-sovety-dlya-biznesa"
                    target="_blank"
                  >
                    {'💡 '}
                    {'Рекомендации по использованию Telegram'}
                  </Link>
                </Advice>
              )}

              <Advice>
                <Link
                  href={
                    lang == 'ru'
                      ? 'https://whatcrm.gitbook.io/whatcrm-docs/v/russian/poleznye-stati/telegram/limity-telegram'
                      : 'https://whatcrm.gitbook.io/whatcrm-docs/v/english/useful-articles/telegram-restrictions'
                  }
                  target="_blank"
                >
                  {'⚠️ '}
                  {t`Telegram restrictions`}
                </Link>
              </Advice>
            </>
          )}

          {['MONDAY', 'TGMONDAY'].includes(client.crm) && (
            <Advice>
              <Link
                href="https://www.youtube.com/watch?v=IiA5tXXxeEY&list=PLRI-SktGhWQzp_KOmtx1AnsN6ryXQIe8f&index=8"
                target="_blank"
              >
                {'📼 '}
                {t`Video tutorials`}
              </Link>
            </Advice>
          )}

          {client.crm == 'PIPEDRIVE' && lang != 'ru' && (
            <Advice>
              <Link
                href="https://whatcrm.gitbook.io/whatcrm-docs/english/integrations-products/pipedrive/how-to-fix-whatsapp-chat-issues-with-short-numbers"
                target="_blank"
              >
                {'🌍 '}
                {t`How to fix issues with short numbers`}
              </Link>
            </Advice>
          )}
        </Wrapper>
      )}
    </>
  );
};

export default Advices;
