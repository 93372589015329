import React, {useEffect, useState} from 'react';
import {t} from 'ttag';
import {getFromLocalStorage, putInLocalStorage} from '../../common/actions.js';

import Advice from 'components/advice/advice';
import Link from 'components/link/link.jsx';
import ErrorPage from 'components/error-page/error-page.jsx';
import Page from 'components/page/page.jsx';
import Preloader from 'components/preloader/preloader.jsx';
import ProductsSection from 'components/products-section/products-section.jsx';
import {useAppContext} from '../../app-context.js';
import {useRequest} from '../../common/hooks';
import {getConnectionCondition} from '../../common/actions.js';
import {getDashboardReq} from '../../common/api/api.js';

import Automation from './automation/automation.jsx';
import Connections from './connections/connections.jsx';
import DenyList from './deny-list/deny-list.jsx';
import HomeContext from './home-context.js';
import NoticeList from './notice-list/notice-list.jsx';
import Partners from './partners/partners.jsx';
import Trial from './trial/trial';

const Home = () => {
  const {user, lang, isForbidden, setUser, setIsForbidden} = useAppContext();

  const [connectionList, setConnectionList] = useState();
  const [noticeList, setNoticeList] = useState();
  const [messageTemplateList, setMessageTemplateList] = useState();

  const [isFeedback, setIsFeedback] = useState(
    !getFromLocalStorage('feedback-2025-02-03')
  );
  const [isMondayFeedback, setIsMondayFeedback] = useState(
    !getFromLocalStorage('feedback-2025-02-07')
  );

  const {request} = useRequest();

  const getDashboard = async () => {
    const res = await request(
      getDashboardReq(
        user.crm,
        user.domain,
        lang,
        user.crm_param_id,
        user.telphin_code,
        user.token
      )
    );

    if (Object.keys(res).length) {
      const {
        instances,
        integration,
        notifications,
        partner = {},
        templates = []
      } = res;

      const instanceList = Object.entries(instances).map(item => ({
        id: parseInt(item[0]),
        ...item[1],
        ...getConnectionCondition(item[1])
      }));

      const params = Object.fromEntries(
        new URLSearchParams(window.location.search)
      );

      const tempUser = {
        ...user,
        ...integration,
        partner,
        widget_code: integration.widget_code || params.widget_code
      };

      setUser(tempUser);
      setNoticeList(notifications);
      setMessageTemplateList(templates);
      setConnectionList(instanceList);

      return;
    }

    setIsForbidden(true);
  };

  useEffect(() => {
    getDashboard();
  }, []);

  const handleClose = () => {
    setIsFeedback(false);
    putInLocalStorage('feedback-2025-02-03', '1');
  };

  const handleMondayClose = () => {
    setIsMondayFeedback(false);
    putInLocalStorage('feedback-2025-02-07', '1');
  };

  return (
    <HomeContext.Provider
      value={{
        connectionList,
        messageTemplateList,
        setConnectionList,
        setMessageTemplateList
      }}
    >
      {isForbidden ? (
        <ErrorPage title="403 Forbidden" />
      ) : connectionList ? (
        <div className="home">
          <NoticeList
            noticeList={noticeList}
            isImportant
            setNoticeList={setNoticeList}
          />

          {user.date_trial > Date.now() / 1000 && <Trial />}

          {user.crm == 'PIPEDRIVE' && lang != 'ru' && isFeedback ? (
            <Advice isWhite onClose={handleClose} style={{width: '100%'}}>
              <Link href="https://forms.gle/oY3rKeoDw1JDinxD8" target="_blank">
                {`⏳ ${t`Got a minute? Give us feedback!`}`}
              </Link>
            </Advice>
          ) : (
            user.crm == 'MONDAY' &&
            lang != 'ru' &&
            isMondayFeedback && (
              <Advice
                isWhite
                onClose={handleMondayClose}
                style={{width: '100%'}}
              >
                <Link
                  href="https://forms.gle/dNQHreXscd9dPea9A "
                  target="_blank"
                >
                  {`⏳ ${t`Got a minute? Give us feedback!`}`}
                </Link>
              </Advice>
            )
          )}

          <Connections />
          <Automation />

          {user.crm != 'AVITO' && user.crm != 'AVITOBX' && <DenyList />}

          {user.crm !== 'MONDAY' && user.crm != 'TGMONDAY' && (
            <ProductsSection />
          )}

          <Partners />
          {/* <NoticeList noticeList={noticeList} setNoticeList={setNoticeList} /> */}
        </div>
      ) : (
        <Page isCenter isTransparent>
          <Preloader />
        </Page>
      )}
    </HomeContext.Provider>
  );
};

export default Home;
