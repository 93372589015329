import React from 'react';
import {t} from 'ttag';
import {useField} from 'formik';

import {Toggle} from '../../../../../../form/form.jsx';
import Wrapper from '../../../../../../wrapper/wrapper.jsx';

const CallNotifications = () => {
  const [field, , helpers] = useField('call_notifications');

  return (
    <Wrapper alignItems="center">
      <Toggle
        label={t`Call notifications`}
        isChecked={field.value}
        onChange={helpers.setValue}
      />
    </Wrapper>
  );
};

export default CallNotifications;
