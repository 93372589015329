import React from 'react';
import {t} from 'ttag';

import {useConnectionContext} from '../../connection-context';
import {useCopyToClipboard} from 'common/hooks';
import Button from 'components/button/button';
import Icons from 'components/icons/icons';
import Text from 'components/text/text';
import Wrapper from 'components/wrapper/wrapper';

const ChatId = () => {
  const {connection} = useConnectionContext();
  const copyToClipboard = useCopyToClipboard();

  const isVisible =
    connection.condition == 'premium' ||
    (connection.condition == 'paid' && connection.tariff_plane == 'Enterprise');

  return (
    <>
      {isVisible && (
        <Wrapper alignItems="center" gap={0}>
          <Text>{`${t`IP address`}: ${connection.chat_id}`}</Text>

          <Button
            color="transparent"
            style={{height: 16}}
            onClick={() => copyToClipboard(connection.chat_id)}
          >
            <Icons.Copy />
          </Button>
        </Wrapper>
      )}
    </>
  );
};

export default ChatId;
