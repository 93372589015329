import React from 'react';
import PropTypes from 'prop-types';
import Bitrix24 from './bitrix24/bitrix24.jsx';
import Amocrm from './amocrm/amocrm.jsx';
import Monday from './monday/monday';
import Avito from './avito/avito';
import Advanced from './advanced/advanced';
import {default as CorePopup} from '../../../../popup/popup.jsx';
import Name from '../../name/name.jsx';
import {useConnectionListContext} from 'components/connection-list/connection-list-context.js';
import {useConnectionContext} from '../../connection-context.js';
import Pipedrive from './pipedrive/pipedrive.jsx';
import Ycl from './ycl/ycl.jsx';

const Popup = ({isClosable}) => {
  const {client, setSettingsId} = useConnectionListContext();
  const {justCrm} = useConnectionContext();

  return (
    <CorePopup
      width={644}
      isClosable={isClosable}
      onClick={() => setSettingsId(-1)}
    >
      <Name style={{marginBottom: 16}} />

      {client.crm == 'AVITO' && <Avito />}
      {client.crm == 'AVITOBX' && <Bitrix24 />}
      {justCrm === 'amocrm' && <Amocrm />}
      {justCrm === 'bitrix24' && <Bitrix24 />}
      {justCrm === 'monday' && <Monday />}
      {client.crm == 'LK' && <Advanced />}
      {justCrm == 'pipedrive' && <Pipedrive />}
      {client.crm == 'YCL' && <Ycl />}
    </CorePopup>
  );
};

Popup.propTypes = {
  isClosable: PropTypes.bool
};

Popup.defaultProps = {
  isClosable: true
};

export default Popup;
