import React from 'react';
import update from 'immutability-helper';
import * as yup from 'yup';
import {t} from 'ttag';
import {Formik, Form} from 'formik';

import CallNotifications from './call-notifications/call-notifications.jsx';
import FirstMessage from './first-message/first-message.jsx';
import Group from './group/group.jsx';
import LineId from './line-id/line-id.jsx';
import ShowChatInTimeline from './show-chat-in-timeline/show-chat-in-timeline.jsx';

import Wrapper from '../../../../../wrapper/wrapper.jsx';
import Line from '../../../../../line/line.jsx';
import Button, {SaveButton} from '../../../../../button/button';
import Preloader from '../../../../../preloader/preloader.jsx';
import EmptyBox from '../../../../../empty-box/empty-box.jsx';

import {useAppContext} from '../../../../../../app-context.js';
import {useConnectionListContext} from '../../../../connection-list-context.js';
import {useConnectionContext} from '../../../connection-context.js';
import {useSettingsContext} from '../../settings-context.js';
import {useRequest} from '../../../../../../common/hooks';
import {updateConnectionConfigReq} from '../../../../../../common/api/bitrix24.js';
import {trimValues} from '../../../../../../common/actions.js';

const Bitrix24 = () => {
  const {setNotificationList} = useAppContext();
  const {client, setSettingsId} = useConnectionListContext();
  const {connection} = useConnectionContext();
  const {connectionConfig, updateConnectionConfig} = useSettingsContext();

  const {crmRequest} = useRequest();

  const handleSubmit = async values => {
    const trimmedValues = trimValues(values);

    const res = await crmRequest({
      crm: client.crm,
      ...updateConnectionConfigReq({
        domain: client.domain,
        chat_id: connection.id,
        data: trimmedValues
      })
    });

    if (Object.keys(res).length > 3) {
      setNotificationList(prevValue =>
        update(prevValue, {
          $push: [
            {
              title: connection.label || `${t`Connection`} ${connection.id}`,
              text: t`The settings have been successfully saved.`
            }
          ]
        })
      );

      updateConnectionConfig({...connectionConfig, ...res});
      setSettingsId(-1);
    }

    return true;
  };

  const initialValues = connectionConfig
    ? {
        call_notifications: connectionConfig.call_notifications ?? true,
        first_message: connectionConfig.first_message || false,
        group: connectionConfig.group || false,
        line_id: connectionConfig.line_id || '',
        show_chat_in_timeline: connectionConfig.show_chat_in_timeline || false
      }
    : {};

  const validationSchema = yup.object().shape({
    first_message: yup.bool(),
    group: yup.bool(),
    line_id: yup.string()
  });

  return (
    <>
      {connectionConfig ? (
        Object.keys(connectionConfig).length ? (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <Wrapper gap={16} isColumn>
                <LineId />

                <Wrapper isColumn>
                  <FirstMessage />
                  <Group />
                  <ShowChatInTimeline />
                  <CallNotifications />
                </Wrapper>
              </Wrapper>

              <Line />

              <Wrapper>
                <SaveButton>{t`Save`}</SaveButton>

                <Button color="white" onClick={() => setSettingsId(-1)}>
                  {t`Cancel`}
                </Button>
              </Wrapper>
            </Form>
          </Formik>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader isMin />
      )}
    </>
  );
};

export default Bitrix24;
