import React from 'react';
import {Formik, Form} from 'formik';
import update from 'immutability-helper';
import {t} from 'ttag';

import Wrapper from '../../../../../wrapper/wrapper.jsx';
import Line from '../../../../../line/line.jsx';
import Button, {SaveButton} from '../../../../../button/button';
import Preloader from '../../../../../preloader/preloader.jsx';
import EmptyBox from '../../../../../empty-box/empty-box.jsx';
import {useAppContext} from '../../../../../../app-context.js';
import {useConnectionListContext} from '../../../../connection-list-context.js';
import {useConnectionContext} from '../../../connection-context.js';
import {useSettingsContext} from '../../settings-context.js';
import {useRequest} from '../../../../../../common/hooks';
import {postPrefs} from 'common/api/ycl.js';
import {trimValues} from '../../../../../../common/actions.js';

import Group from './group/group.jsx';

const Ycl = () => {
  const {setNotificationList} = useAppContext();
  const {client, setSettingsId} = useConnectionListContext();
  const {connection} = useConnectionContext();
  const {connectionConfig, updateConnectionConfig} = useSettingsContext();

  const {crmRequest} = useRequest();

  const handleSubmit = async values => {
    const trimmedValues = trimValues(values);

    const res = await crmRequest({
      crm: client.crm,
      ...postPrefs(client.domain, connection.id, trimmedValues)
    });

    if (Object.keys(res).length > 3) {
      setNotificationList(prevValue =>
        update(prevValue, {
          $push: [
            {
              title: connection.label || `${t`Connection`} ${connection.id}`,
              text: t`The settings have been successfully saved.`
            }
          ]
        })
      );

      updateConnectionConfig(res);
      setSettingsId(-1);
    }

    return true;
  };

  const initialValues = connectionConfig
    ? {group: connectionConfig.group || false}
    : {};

  return (
    <>
      {connectionConfig ? (
        Object.keys(connectionConfig).length ? (
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            <Form>
              <Group />
              <Line />

              <Wrapper>
                <SaveButton>{t`Save`}</SaveButton>

                <Button color="white" onClick={() => setSettingsId(-1)}>
                  {t`Cancel`}
                </Button>
              </Wrapper>
            </Form>
          </Formik>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default Ycl;
