import React from 'react';
import ReactCollapsible from 'react-collapsible';
import {t} from 'ttag';

import * as Title from 'components/title/title';
import Icons from 'components/icons/icons.jsx';
import Text from 'components/text/text.jsx';
import Tooltip from 'components/tooltip/tooltip';
import Wrapper from 'components/wrapper/wrapper.jsx';
import List from 'components/list/list';
import {useAppContext} from 'app-context';
import {usePaymentContext} from 'components/payment/payment-context';
import {useTariffPlanContext} from '../../tariff-plan-context';

import {
  getAvitoFeatureList,
  getCheckerFeatureList,
  getFeatureList
} from './actions';

const FeatureList = () => {
  const {lang} = useAppContext();

  const {
    client,
    connection,
    connectionIdList,
    connectionList,
    isFeatureListOpen,
    setIsFeatureListOpen,
    tariffList
  } = usePaymentContext();

  const {tariffOptions} = useTariffPlanContext();
  const {packet_name} = tariffOptions[0];

  const getFeatures = () => {
    const firstSelectedConnection = connectionList?.find(
      item => item.id == connectionIdList?.[0]
    );

    if (
      client.crm == 'AVITO' ||
      client.crm == 'AVITOBX' ||
      connection?.version == 'avito' ||
      firstSelectedConnection?.version == 'avito'
    )
      return getAvitoFeatureList();
    else if (client.crm == 'CHECKER')
      return getCheckerFeatureList(
        packet_name,
        lang,
        client.domain.includes('bitrix24')
      );

    return getFeatureList(
      client.crm,
      packet_name,
      lang,
      !tariffList || 'Enterprise' in tariffList
    );
  };

  const featureList = getFeatures();

  const diff = featureList.filter(
    item => item.Start || item.Pro || ('isShown' in item && item.isShown)
  ).length;

  const other =
    packet_name == 'Enterprise' ? 200 : packet_name == 'Pro' ? 100 : 50;

  const photo =
    packet_name == 'Enterprise' ? 64 : packet_name == 'Pro' ? 32 : 16;

  const video =
    packet_name == 'Enterprise' ? 128 : packet_name == 'Pro' ? 64 : 16;

  return (
    <div style={{marginBottom: 12}}>
      <div style={{marginBottom: 8}}>
        {featureList.slice(0, diff).map((item, i) => (
          <Wrapper gap={0} key={i} noWrap>
            <>
              {item[packet_name] && item ? (
                <Icons.Check />
              ) : (
                <Icons.Times color="light" />
              )}

              <Text
                color={item[packet_name] ? 'dark' : 'light'}
                style={{paddingTop: 8}}
                isSpan
              >
                {item.name}
              </Text>

              {item[packet_name] && 'tooltip' in item && (
                <Tooltip>
                  <Text color="dark">{item.tooltip as string}</Text>
                </Tooltip>
              )}

              {'isLimits' in item && item.isLimits && (
                <Tooltip>
                  <List
                    items={[
                      t`Photos up to ${photo} MB`,
                      t`Videos up to ${video}* MB`,
                      t`Other files up to ${other} MB`
                    ]}
                    style={{marginBottom: 8}}
                  />

                  <Text isSmall>{t`*After file processing.`}</Text>
                </Tooltip>
              )}
            </>
          </Wrapper>
        ))}
      </div>

      {diff < featureList.length && (
        <Wrapper
          alignItems="center"
          justifyContent="space-between"
          isClickable
          onClick={() => setIsFeatureListOpen(prevValue => !prevValue)}
        >
          <Title.H3>{isFeatureListOpen ? t`Hide` : t`View more`}</Title.H3>
          <Icons.ArrowDown isRotate={isFeatureListOpen} />
        </Wrapper>
      )}

      <ReactCollapsible
        transitionTime={200}
        trigger={<></>}
        open={isFeatureListOpen}
      >
        {featureList.slice(diff).map((item, i) => (
          <Wrapper gap={0} key={i} noWrap>
            <>
              {item[packet_name] ? (
                <Icons.Check />
              ) : (
                <Icons.Times color="light" />
              )}

              <Text
                color={item[packet_name] ? 'dark' : 'light'}
                style={{paddingTop: 8}}
                isSpan
              >
                {item.name}
              </Text>

              {item[packet_name] && 'tooltip' in item && (
                <Tooltip>
                  <Text color="dark">{item.tooltip as string}</Text>
                </Tooltip>
              )}
            </>
          </Wrapper>
        ))}
      </ReactCollapsible>
    </div>
  );
};

export default FeatureList;
