import React, {useState} from 'react';
import _ from 'lodash';
import {t} from 'ttag';

import {NavTab} from '../../../components/nav/nav.jsx';
import ConnectionList from '../../../components/connection-list/connection-list.jsx';
import PaymentHistory from 'components/payment-history/payment-history';
import Section from '../../../components/section/section.jsx';
import SubscriptionList from '../../../components/subscription-list/subscription-list';
import {useAppContext} from '../../../app-context.js';
import {useHomeContext} from '../home-context.js';
import {usePaymentHistory, useSubscriptionList} from 'common/hooks';

import Staff from './staff/staff.jsx';

const Connections = () => {
  const {user} = useAppContext();
  const {connectionList, setConnectionList} = useHomeContext();

  const [tab, setTab] = useState('connectionList');

  const paymentHistory = usePaymentHistory(user.id);
  const subscriptionList = useSubscriptionList(user.id);

  const options = [
    {
      value: 'connectionList',
      label: t`My connections`
    }
  ];

  if (
    !['B24', 'TELPHGRAM', 'AVITOBX'].includes(user.crm) ||
    user.is_admin > 0
  ) {
    options.push({
      value: 'staff',
      label: t`Staff`
    });
  }

  if ((user.crm != 'MONDAY' && user.crm != 'TGMONDAY') || user.is_admin > 0) {
    options.push(
      {
        value: 'payment-history',
        label: t`Payment history`
      },
      {
        value: 'subscription-list',
        label: t`Subscriptions`
      }
    );
  }

  const navParams = {
    options: options,
    value: tab,
    onChange: setTab
  };

  return (
    <Section title={t`Connections`} navParams={navParams} isDefaultOpen>
      <NavTab tab="connectionList">
        <ConnectionList
          connectionList={connectionList}
          client={_.pick(user, ['crm', 'domain', 'is_integration', 'id'])}
          setConnectionList={setConnectionList}
        />
      </NavTab>

      <NavTab tab="staff">
        <Staff />
      </NavTab>

      <NavTab tab="payment-history">
        <PaymentHistory paymentHistory={paymentHistory} />
      </NavTab>

      <NavTab tab="subscription-list">
        <SubscriptionList subscriptionList={subscriptionList} />
      </NavTab>
    </Section>
  );
};

export default Connections;
