import React from 'react';
import Bitrix24 from './bitrix24/bitrix24.jsx';
import Amocrm from './amocrm/amocrm.jsx';
import Monday from './monday/monday';
import Grid from '../../../../grid/grid.jsx';
import Preloader from '../../../../preloader/preloader.jsx';
import {useConnectionListContext} from 'components/connection-list/connection-list-context.js';
import {useConnectionContext} from '../../connection-context.js';
import {useSettingsContext} from '../settings-context.js';
import Avito from './avito/avito';
import Pipedrive from './pipedrive/pipedrive.jsx';
import Ycl from './ycl/ycl.jsx';

const Config = () => {
  const {client} = useConnectionListContext();
  const {justCrm} = useConnectionContext();
  const {connectionConfig} = useSettingsContext();

  return (
    <>
      {connectionConfig ? (
        (Object.keys(connectionConfig).length > 0 ||
          justCrm == 'pipedrive') && (
          <Grid elements="auto" gap={8}>
            {client.crm == 'AVITO' && <Avito />}
            {client.crm == 'AVITOBX' && <Bitrix24 />}
            {justCrm === 'bitrix24' && <Bitrix24 />}
            {justCrm === 'amocrm' && <Amocrm />}
            {justCrm === 'monday' && <Monday />}
            {justCrm == 'pipedrive' && <Pipedrive />}
            {client.crm == 'YCL' && <Ycl />}
          </Grid>
        )
      ) : (
        <Preloader isMin style={{padding: 0}} />
      )}
    </>
  );
};

export default Config;
