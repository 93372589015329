import React, {useState, useEffect} from 'react';
import {msgid, ngettext, t} from 'ttag';

import EmptyBox from 'components/empty-box/empty-box.jsx';
import Filter from 'components/filter/filter.jsx';
import Preloader from 'components/preloader/preloader.jsx';
import Table, {
  TableBody,
  TableCell,
  TableHead
} from 'components/table/table.jsx';
import Text from 'components/text/text.jsx';
import {useAppContext} from 'app-context.js';
import {inflectNumberal} from 'common/actions.js';
import * as AppEnv from 'app-env';

import PaymentRecord from './payment-record/payment-record';

interface Values {
  domains: string[];
}

interface PaymentHistory {
  paymentHistory: AppEnv.Payment[] | undefined;
}

const PaymentHistory = ({paymentHistory}: PaymentHistory) => {
  const {user, lang} = useAppContext();

  const [filteredPaymentHistory, setFilteredPaymentHistory] = useState<
    AppEnv.Payment[]
  >([]);

  useEffect(() => {
    if (paymentHistory) setFilteredPaymentHistory(paymentHistory);
  }, [paymentHistory]);

  const handleFilterChange = (values: Values) => {
    if (values.domains.length)
      return setFilteredPaymentHistory(
        paymentHistory?.filter(entry =>
          values.domains.includes(entry.domain)
        ) || []
      );

    setFilteredPaymentHistory(paymentHistory || []);
  };

  const domains = paymentHistory
    ? paymentHistory.reduce((acc: string[], entry) => {
        acc.push(entry.domain);
        return acc;
      }, [])
    : [];

  const isRefund = !!(
    filteredPaymentHistory &&
    filteredPaymentHistory.find(
      item => item.is_deleted == 1 || item.is_deleted == 2
    )
  );

  return (
    <div>
      {paymentHistory ? (
        paymentHistory.length ? (
          <>
            <Filter
              domains={domains}
              style={{marginBottom: 16}}
              onChange={handleFilterChange}
            />

            {filteredPaymentHistory.length ? (
              <>
                <Text isSmall style={{marginBottom: 8}}>
                  {filteredPaymentHistory.length}
                  {' '}
                  {lang === 'ru'
                    ? inflectNumberal({
                        value: filteredPaymentHistory.length,
                        labels: ['запись', 'записи', 'записей']
                      })
                    : lang === 'uk'
                      ? inflectNumberal({
                          value: filteredPaymentHistory.length,
                          labels: ['запис', 'записи', 'записів']
                        })
                      : ngettext(
                          msgid`entry`,
                          `entries`,
                          filteredPaymentHistory.length
                        )}
                </Text>

                <Table isEmbedded>
                  <TableHead>
                    <TableCell>{`${t`Date`} (UTC+3)`}</TableCell>
                    <TableCell>{t`Amount`}</TableCell>
                    <TableCell>{t`Period`}</TableCell>
                    <TableCell>{t`Tariff plan`}</TableCell>
                    {!!user.is_partner && <TableCell>{t`Domain`}</TableCell>}
                    {isRefund && <TableCell />}
                  </TableHead>

                  <TableBody>
                    {filteredPaymentHistory.map((item, i) => (
                      <PaymentRecord
                        key={i}
                        paymentRecord={item}
                        isRefund={isRefund}
                      />
                    ))}
                  </TableBody>
                </Table>
              </>
            ) : (
              <EmptyBox />
            )}
          </>
        ) : (
          <EmptyBox />
        )
      ) : (
        <Preloader />
      )}
    </div>
  );
};

export default PaymentHistory;
