import React from 'react';
import {useField} from 'formik';
import {t} from 'ttag';
import {Toggle} from '../../../../../../form/form.jsx';
import Wrapper from 'components/wrapper/wrapper.jsx';
import Tooltip from 'components/tooltip/tooltip.jsx';
import Text from 'components/text/text.jsx';

const MessagingNotify = () => {
  const [field, , helpers] = useField('messaging_notify');

  return (
    <Wrapper alignItems="center">
      <Toggle
        label={t`Receiving new messages`}
        isChecked={field.value}
        onChange={helpers.setValue}
      />

      <Tooltip>
        <Text>
          {t`If turned off, new messages will not be displayed in Pipedrive.`}
        </Text>
      </Tooltip>
    </Wrapper>
  );
};

export default MessagingNotify;
