import React from 'react';
import {t} from 'ttag';

import Entry from '../entry/entry';
import {useConnectionContext} from 'components/connection-list/connection/connection-context';
import {useSettingsContext} from '../../settings-context';

const Ycl = () => {
  const {connection} = useConnectionContext();
  const {connectionConfig} = useSettingsContext();

  return (
    <>
      {!connection.is_limited && (
        <Entry
          label={t`Working with groups`}
          value={connectionConfig.group ? t`Yes` : t`No`}
        />
      )}
    </>
  );
};

export default Ycl;
