import React from 'react';
import {t} from 'ttag';
import Entry from '../entry/entry.jsx';
import {useConnectionContext} from '../../../connection-context.js';
import {useSettingsContext} from '../../settings-context.js';

const Bitrix24 = () => {
  const {connection} = useConnectionContext();
  const {connectionConfig} = useSettingsContext();

  const openLine =
    connectionConfig.lines?.find(
      item => item.id === connectionConfig.line_id
    ) || {};

  return (
    <>
      <Entry
        label={t`Open line`}
        value={
          openLine.name === 'Add new line' ? t`Add a new line` : openLine.name
        }
      />

      <Entry
        label={t`Create an open line chat when sending SMS`}
        value={connectionConfig.first_message ? t`Yes` : t`No`}
      />

      {!connection.is_limited && (
        <Entry
          label={t`Working with groups`}
          value={connectionConfig.group ? t`Yes` : t`No`}
        />
      )}

      <Entry
        label={t`Message history in card timeline`}
        value={connectionConfig.show_chat_in_timeline ? t`Yes` : t`No`}
      />

      <Entry
        label={t`Call notifications`}
        value={connectionConfig.call_notifications ? t`Yes` : t`No`}
      />
    </>
  );
};

export default Bitrix24;
