import React from 'react';

import Wrapper from '../../wrapper/wrapper.jsx';
import {useConnectionListContext} from '../connection-list-context.js';

import Creating from './creating/creating.jsx';
import Selection from './selection/selection.jsx';
import Categorization from './categorization/categorization.jsx';
import Searching from './searching/searching.jsx';

const Actions = () => {
  const {connectionList, visibleConnectionList, isConnector} =
    useConnectionListContext();

  const isCreatingShown =
    !isConnector || (isConnector && connectionList?.length === 0);

  const isPremium = !!visibleConnectionList?.find(item => item.is_premium);
  const isSelectionShown = visibleConnectionList?.length > 1 && !isPremium;

  const isShown =
    isCreatingShown || isSelectionShown || connectionList?.length > 1;

  return (
    <>
      {isShown && (
        <Wrapper justifyContent="space-between" alignItems="flex-end" gap={16}>
          <Wrapper>
            {isCreatingShown && <Creating />}
            {isSelectionShown && <Selection />}
          </Wrapper>

          <Wrapper
            className="connection-list__filter"
            alignItems="flex-end"
            isColumn
          >
            {connectionList?.length > 1 && <Categorization />}
            {connectionList?.length > 4 && <Searching />}
          </Wrapper>
        </Wrapper>
      )}
    </>
  );
};

export default Actions;
