import update from 'immutability-helper';
import {t} from 'ttag';

import {createConnectionReq} from '../../common/api/api.js';
import {getConnectionCondition} from '../../common/actions.js';
import {putConnection} from 'common/api/pipedrive.js';
import {useAppContext} from '../../app-context.js';
import {useConnectionListContext} from './connection-list-context.js';
import {useRequest} from '../../common/hooks';

const useCreateConnection = () => {
  const {setNotificationList} = useAppContext();

  const {
    client,
    managerList,
    onCreate,
    pipelineList,
    setConnectionList,
    setConnectionRequiringPreset,
    setOpenConnectionId,
    stageList
  } = useConnectionListContext();

  const {crmRequest, request} = useRequest();

  const createConnection = async ({label, version = 'whatcrm', place}) => {
    const res = await request(
      createConnectionReq(client.crm, client.domain, version, label, place)
    );

    if (Object.keys(res).length) {
      const condition = getConnectionCondition(res);

      if (client.crm == 'PIPEDRIVE' && !condition.is_limited) {
        const pipeline = pipelineList?.[0];

        const responsible_ids = managerList?.map(
          manager => manager.crm_user_id
        );

        const stage = stageList?.[0];

        await crmRequest({
          crm: 'PIPEDRIVE',
          ...putConnection(client.domain, res.chat_key, {
            is_group: true,
            pipeline_id: pipeline?.id,
            responsible_ids,
            stage_id: stage?.id,
            strategy: 'random'
          })
        });
      }

      setConnectionList(prevValue =>
        update(prevValue, {
          $push: [{...res, ...getConnectionCondition(res)}]
        })
      );

      onCreate(res);

      if (client.isCustomer) {
        setNotificationList(prevValue =>
          update(prevValue, {
            $push: [
              {title: `${t`Connection`} ${res.id} ${t`has been created.`}`}
            ]
          })
        );

        return;
      }

      if (client.crm === 'AMO' || client.crm === 'TELEGRAM') {
        setConnectionRequiringPreset(res.id);
      }

      setOpenConnectionId(res.id);
    }
  };

  return createConnection;
};

export default useCreateConnection;
