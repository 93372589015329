import React from 'react';
import {t} from 'ttag';
import {useField} from 'formik';

import * as Title from 'components/title/title';
import {SingleSelect} from 'components/form/form';

const Place = () => {
  const [field, , helpers] = useField('place');

  const options = [
    {label: `🇫🇮 ${t`Finland`}`, value: 'Finland'},
    {label: `🇩🇪 ${t`Germany`}`, value: 'Germany'},
    {label: `🇷🇺 ${t`Russia`}`, value: 'Russian'}
  ];

  return (
    <div style={{marginBottom: 16}}>
      <Title.H3 style={{marginBottom: 8}}>{t`Server location`}</Title.H3>

      <SingleSelect
        {...field}
        options={options}
        placeholder={t`Server location`}
        onChange={helpers.setValue}
      />
    </div>
  );
};

export default Place;
