import React, {useEffect, useState} from 'react';
import {t} from 'ttag';

import {Checkbox} from 'components/form/form.jsx';
import Button from 'components/button/button';
import Wrapper from 'components/wrapper/wrapper';
import {useAppContext} from 'components/../app-context.js';
import {usePaymentContext} from 'components/payment/payment-context';
import {useTariffPlanContext} from '../../tariff-plan-context';

import Confirmation from './confirmation/confirmation';
import Method from './method/method';
import Total from './total/total';
import TransitionContext from './transition-context';

interface UrlParams {
  [index: string]: number | string | (number | undefined)[] | undefined;
}

const Transition = () => {
  const {user, lang} = useAppContext();
  const {client, connection, connectionIdList, selectedTariffId} =
    usePaymentContext();

  const {isSelected} = useTariffPlanContext();

  const [method, setMethod] = useState<'cloud' | 'stripe' | 'telphin'>();
  const [promoCode, setPromoCode] = useState('');

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isMethodOpen, setIsMethodOpen] = useState(false);
  const [isSubscription, setIsSubscription] = useState(true);

  const openPaymentForm = () => {
    const urlParams: UrlParams = {
      domain: client.domain,
      instance_id: connection
        ? Object.keys(connection).length
          ? [connection?.id]
          : connectionIdList
        : undefined,
      is_subscribed: isSubscription ? 1 : 0,
      redirect: window.location.href,
      tariff_id: selectedTariffId,
      type: client.crm,
      ...(connectionIdList && connectionIdList.length > 1
        ? {quantity: connectionIdList.length}
        : {}),
      ...(client.isCustomer ? {partner_ref: user.id} : {})
    };

    if (promoCode && method != 'telphin') urlParams.promo_code = promoCode;

    const urlSearchParams = new URLSearchParams(urlParams as any); // eslint-disable-line

    const baseURL =
      window.location.host.slice(-2) == 'ru'
        ? process.env.REACT_APP_API_RU
        : process.env.REACT_APP_API;

    const src = `${baseURL}/payments/${method || 'stripe'}?${urlSearchParams}`;

    window.open(src, user.crm == 'PIPEDRIVE' ? '_blank' : '_self');
  };

  const isCloudpaymentsShown = lang === 'ru' && user.country !== 'UA';

  const isTelphinShown =
    client.crm != 'CHECKER' &&
    !client.isCustomer &&
    (user.telphin_code || lang === 'ru');

  useEffect(() => {
    if (isCloudpaymentsShown) return;
    else if (isTelphinShown) return;

    setMethod('stripe');
  }, [isCloudpaymentsShown, isTelphinShown]);

  const handleMethodClick = (res: boolean) => {
    if (res) {
      setIsMethodOpen(false);
      setIsConfirmationOpen(true);
      return;
    }

    setIsMethodOpen(false);
  };

  const handleConfirmationClick = (res: boolean) => {
    if (res) {
      openPaymentForm();
      return;
    }

    setIsConfirmationOpen(false);
    setMethod(undefined);
  };

  return (
    <TransitionContext.Provider value={{method}}>
      <div>
        {connectionIdList && connectionIdList.length > 1 && <Total />}

        <Wrapper alignItems="center" gap={12}>
          <Button
            isDisabled={!isSelected}
            onClick={() => setIsMethodOpen(true)}
          >
            {t`Continue`}
          </Button>

          <Checkbox
            label={t`Subscribe`}
            isChecked={isSubscription}
            isDisabled={!isSelected}
            onChange={setIsSubscription}
          />
        </Wrapper>

        {isMethodOpen && (
          <Method
            isCloudpaymentsShown={isCloudpaymentsShown}
            isTelphinShown={isTelphinShown}
            onClick={handleMethodClick}
            setMethod={setMethod}
            promoCode={promoCode}
            setPromoCode={setPromoCode}
          />
        )}

        {isConfirmationOpen && (
          <Confirmation
            isSubscription={isSubscription}
            onClick={handleConfirmationClick}
          />
        )}
      </div>
    </TransitionContext.Provider>
  );
};

export default Transition;
