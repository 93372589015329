import React from 'react';
import {t} from 'ttag';
import Line from '../../../line/line.jsx';
import Link from '../../../link/link.jsx';
import {useAppContext} from 'app-context.js';
import {useConnectionListContext} from '../../connection-list-context.js';
import {useConnectionContext} from '../connection-context.js';

const SettingsTrigger = () => {
  const {user} = useAppContext();
  const {client, isConnector, setSettingsId} = useConnectionListContext();
  const {connection, justCrm} = useConnectionContext();

  const getStyle = () => {
    if (client.isCustomer || isConnector) {
      return {display: 'none'};
    } else if (justCrm == 'amocrm' || justCrm == 'monday') {
      return connection.phone ? {} : {visibility: 'hidden'};
    } else if (
      client.crm == 'AVITO' ||
      client.crm == 'AVITOBX' ||
      justCrm == 'bitrix24'
    ) {
      return {};
    } else if (
      (connection.version == 'telegram' || connection.version == 'whatcrm') &&
      user.crm == 'LK' &&
      !!user.widget_code
    )
      return {};
    else if (justCrm == 'pipedrive') return {};
    else if (client.crm == 'YCL') return {};

    return {display: 'none'};
  };

  const style = getStyle();

  return (
    <div style={style}>
      <Line />

      <Link
        isFake
        isBold
        onClick={() =>
          setSettingsId(prevValue => (prevValue > -1 ? -1 : connection.id))
        }
      >
        {t`Change settings`}
      </Link>
    </div>
  );
};

export default SettingsTrigger;
