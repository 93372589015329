import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';

import {useAppContext} from 'app-context';
import {useConnectionListContext} from '../../connection-list-context.js';
import Button from '../../../button/button';
import Icons from '../../../icons/icons.jsx';
import useCreateConnection from '../../create-connection.js';

import Config from './config/config.jsx';

const Creating = () => {
  const {user} = useAppContext();
  const {client, connectionList} = useConnectionListContext();

  const [isFetching, setIsFetching] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const createConnection = useCreateConnection();

  useEffect(() => {
    if (client.crm != 'MONDAY') return;
    else if (connectionList?.length > 0) return;
    else if (user.isNew != 'true') return;

    createConnection({version: 'whatcrm'});
  }, []);

  const getVersion = () => {
    if (
      client.crm === 'AMO' ||
      client.crm == 'B24' ||
      client.crm == 'MONDAY' ||
      client.crm == 'YCL'
    )
      return 'whatcrm';
    else if (client.crm == 'AVITO' || client.crm == 'AVITOBX') return 'avito';
    else if (
      client.crm === 'TELEGRAM' ||
      client.crm === 'TELPHGRAM' ||
      client.crm == 'TGMONDAY'
    )
      return 'telegram';
  };

  const handleClick = async () => {
    if (client.crm === 'LK') return setIsOpen(true);

    setIsFetching(true);
    await createConnection({version: getVersion()});
    setIsFetching(false);
  };

  const handleCreate = async values => {
    await createConnection(values);
    setIsOpen(false);
    return true;
  };

  return (
    <>
      <Button isDisabled={isFetching} onClick={handleClick}>
        <Icons.Plus />
        {t`Create connection`}
      </Button>

      {isOpen && (
        <Config onCreate={handleCreate} onClose={() => setIsOpen(false)} />
      )}
    </>
  );
};

Creating.propTypes = {
  onCreate: PropTypes.func
};

Creating.defaultProps = {
  onCreate: () => {}
};

export default Creating;
