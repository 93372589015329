import React from 'react';
import PropTypes from 'prop-types';
import {t} from 'ttag';

import Wrapper from 'components/wrapper/wrapper';
import * as Title from 'components/title/title.jsx';
import Button from 'components/button/button';
import Image from 'components/image/image.jsx';
import Text from 'components/text/text.jsx';

const Product = ({product}) => (
  <div className="product">
    <div style={{marginBottom: 16}}>
      <Image src={product.image} style={{marginBottom: 16}} />
      <Title.H3 style={{marginBottom: 8}}>{product.description_short}</Title.H3>
      <Text>{product.description}</Text>
    </div>

    {(!!product.link || !!product.guide) && (
      <Wrapper>
        {!!product.link && (
          <Button
            color={product.link.color}
            href={product.link.href}
            target="_blank"
            isLink
          >
            {product.link.text}
          </Button>
        )}

        {!!product.guide && (
          <Button color="white" href={product.guide} target="_blank" isLink>
            {t`Description`}
          </Button>
        )}
      </Wrapper>
    )}
  </div>
);

Product.propTypes = {
  product: PropTypes.shape({
    description_short: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    link: PropTypes.object,
    skip: PropTypes.bool,
    guide: PropTypes.string
  })
};

Product.defaultProps = {
  product: {}
};

export default Product;
