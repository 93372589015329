import React from 'react';
import {t} from 'ttag';
import {Form, Formik} from 'formik';

import {useConnectionContext} from 'components/connection-list/connection/connection-context';
import Popup from 'components/popup/popup';
import * as Title from 'components/title/title';
import Line from 'components/line/line';
import Wrapper from 'components/wrapper/wrapper';
import Button, {SaveButton} from 'components/button/button';
import useDeleteConnection from 'components/connection-list/connection/delete-connection';
import Text from 'components/text/text';

import Place from './place/place';

const PlaceForm = ({setIsActive}) => {
  const {connection} = useConnectionContext();
  const deleteConnection = useDeleteConnection();

  const handleSubmit = async values => {
    if (connection.place == values.place) return setIsActive(false);

    await deleteConnection({
      label: connection.label,
      version: connection.version,
      place: values.place
    });

    return true;
  };

  return (
    <Popup onClick={() => setIsActive(false)}>
      <Title.H2
        style={{marginBottom: 16}}
      >{t`Change server location`}</Title.H2>

      <Formik initialValues={{place: connection.place}} onSubmit={handleSubmit}>
        <Form>
          <Place />
          <Text
            style={{marginBottom: 8}}
          >{t`After changing the server location, you will need to reconnect the phone number.`}</Text>
          <Line />

          <Wrapper>
            <SaveButton>{t`Save`}</SaveButton>

            <Button color="white" onClick={() => setIsActive(false)}>
              {t`Close`}
            </Button>
          </Wrapper>
        </Form>
      </Formik>
    </Popup>
  );
};

export default PlaceForm;
